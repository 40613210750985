<template>
<div>
  <p class="title is-2">Taxe</p>
  <p class="subtitle">si impozite</p>
</div>
</template>

<script>
export default {
  name: "TaxeImpozite"
}
</script>

<style scoped>

</style>